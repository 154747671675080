import React from "react";
import { NavLink } from "react-router-dom";
import styled from "@emotion/styled/macro";

import { CATEGORIES } from "../constants";

const Container = styled.div`
  display: flex;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 30px;
  align-items: center;

  @media screen and (min-width: 1200px) {
    flex-direction: row;
  }
`;

const Block = styled.div``;

const Wrapper = styled.div`
  color: black;
  :hover {
    color: #19b090;
  }
`;

const Title = styled.h1`
  text-decoration: none;
  cursor: pointer;
  font-size: 2em;

  @media screen and (min-width: 768px) {
    font-size: 3.5em;
  }

  @media screen and (min-width: 1200px) {
    font-size: 4em;
  }
`;

const Description = styled.h2`
  font-size: 1em;
  line-height: 1.5em;
  margin-bottom: 1.5em;
  margin-top: 1.5em;

  @media screen and (min-width: 768px) {
    font-size: 2em;
  }

  @media screen and (min-width: 1200px) {
    font-size: 1em;
  }
`;

const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }

  @media screen and (min-width: 1200px) {
    height: 100%;
    align-self: flex-end;
  }
`;

const Link = styled(NavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  margin-bottom: 8px;
  text-decoration: none;
  color: #19b090;
  border: 1px solid black;
  height: 18px;
  padding: 8px 14px;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  width: 100%;
`;

const Header = () => (
  <Container>
    <Block>
      <NavLink to="/">
        <Wrapper>
          <Title>ANNE</Title>
          <Title>LAUREEN</Title>
          <Title>FOTOGRAFIE</Title>
        </Wrapper>
      </NavLink>
      <Description>
        Fotograaf van mensen. Van de chaos, de emoties. Het gewone en het
        bijzondere. Jouw verhaal op beeld.
      </Description>
    </Block>
    <Navigation>
      <Link
        activeStyle={{
          border: "1px solid #19b090",
        }}
        to="/over"
      >
        Over
      </Link>
      {CATEGORIES.map((category, i) => (
        <Link
          activeStyle={{
            border: "1px solid #19b090",
          }}
          key={i}
          to={category.key}
        >
          {category.label}
        </Link>
      ))}
    </Navigation>
  </Container>
);

export default Header;
