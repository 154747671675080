import React, { useContext } from "react";
import styled from "@emotion/styled/macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import {
  faLinkedinIn,
  faInstagram,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons";

import { useCookies } from "react-cookie";
import { AuthContext } from "../Context";

const Container = styled.div`
  flex-shrink: 0;
  display: flex;
  margin: 10px auto;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

const Socials = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 250px;
  margin-bottom: 30px;
`;

const Link = styled.a`
  text-decoration: none;
  color: black;
  cursor: pointer;
  :hover {
    color: #19b090;
  }
`;

const Text = styled.div``;

const Logout = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 8px;
  text-decoration: none;
  color: #19b090;
  border: 1px solid black;
  height: 18px;
  padding: 8px 14px;
  text-align: center;
  cursor: pointer;
  white-space: nowrap;
  width: 80px;
`;

const Footer = () => {
  const [authContext, setAuthContext] = useContext(AuthContext);
  const [cookies, setCookie, removeCookie] = useCookies(["annelaureen-token"]);

  const logout = () => {
    removeCookie("annelaureen-token");
    setAuthContext({
      authenticated: false,
      token: "",
    });
  };

  return (
    <Container>
      <Socials>
        <Link
          href="https://www.facebook.com/annelaureenfotografie"
          target="_blank"
        >
          <FontAwesomeIcon icon={faFacebookF} size="lg" />
        </Link>
        <Link
          href="https://www.instagram.com/annelaureenfotografie"
          target="_blank"
        >
          <FontAwesomeIcon icon={faInstagram} size="lg" />
        </Link>
        <Link
          href="https://www.linkedin.com/in/laureen-van-rijckevorsel-klok-779a2789"
          target="_blank"
        >
          <FontAwesomeIcon icon={faLinkedinIn} size="lg" />
        </Link>
        <Link href="mailto:info@annelaureen.nl">
          <FontAwesomeIcon icon={faEnvelope} size="lg" />
        </Link>
      </Socials>
      <Text>Copyright © 2024 annelaureen.nl</Text>
      {authContext.authenticated && <Logout onClick={logout}>Uitloggen</Logout>}
    </Container>
  );
};

export default Footer;
