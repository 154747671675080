import React, { useState, useEffect, useContext } from "react";
import styled from "@emotion/styled/macro";

import axios from "axios";

import { ThemeProvider } from "mineral-ui/themes";

import Create from "./Create";
import List from "./List";

import { AuthContext } from "../../Context";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  border-color: #c8d1e0;
  border-radius: 0.1875em;
  border-style: solid;
  border-width: 1px;
  padding: 10px;
  width: 80%;
  margin: 0 auto;
`;

const Admin = () => {
  const [data, setData] = useState([]);
  const [authContext, setAuthContext] = useContext(AuthContext);

  useEffect(() => {
    const fetchData = async () => {
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${authContext.token}`;
      const result = await axios.get("/api/list");
      setData(result.data);
    };
    fetchData();
  }, []);

  const refetchData = () => {
    axios.get("/api/list").then(({ data }) => setData(data));
  };

  const prepareData = (data) => {
    return data.map((item) => ({
      ...item,
      delete: {
        id: item.id,
        refetch: refetchData,
      },
      published: {
        id: item.id,
        value: item.published,
        refetch: refetchData,
      },
      category: {
        id: item.id,
        value: item.category,
        refetch: refetchData,
      },
      frontpage: {
        id: item.id,
        value: item.frontpage,
        refetch: refetchData,
      },
      date: {
        id: item.id,
        value: item.date,
        refetch: refetchData,
      },
    }));
  };

  return (
    <ThemeProvider>
      <Container>
        <Create refetch={refetchData} />
        <List data={prepareData(data)} refetch={refetchData} />
      </Container>
    </ThemeProvider>
  );
};

export default Admin;
