import React, { useEffect, useState } from "react";
import axios from "axios";
import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";

function PhotoGallery({ category = null }) {
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);
  const [media, setMedia] = useState([]);

  useEffect(() => {
    axios
      .get(`/api/media${category ? `?category=${category}` : ""}`)
      .then((result) => {
        setMedia(
          result.data.map((item) => ({
            ...item,
            src: `https://storage.googleapis.com/ys-node-01/annelaureen.nl/${item.src}`,
          }))
        );
      });
  }, [category]);

  useEffect(() => {
    if (viewerIsOpen) {
      setTimeout(() => {
        document.querySelector(".react-images__pager").style.display = "flex";
        document.querySelector(".react-images__pager").style["align-items"] =
          "center";
      }, 0);
    }
  }, [viewerIsOpen]);

  const openLightbox = (event, obj) => {
    setCurrentImage(obj.index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  return (
    <>
      <Gallery photos={media} onClick={openLightbox} />
      <ModalGateway>
        {viewerIsOpen ? (
          <Modal onClose={closeLightbox}>
            <Carousel
              currentIndex={currentImage}
              views={media.map((x) => ({
                ...x,
              }))}
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  );
}

export default PhotoGallery;
