const CATEGORIES = [
  {
    key: "mensen",
    label: "Mensen",
  },
  {
    key: "theater",
    label: "Theater & Dans",
  },
  {
    key: "evenementen",
    label: "Evenementen",
  },
];

const CATEGORY_LABELS = {
  mensen: "Mensen",
  theater: "Theater & Dans",
  evenementen: "Evenementen",
};

export { CATEGORY_LABELS, CATEGORIES };
