import React, { useState, useContext } from "react";
import styled from "@emotion/styled/macro";
import { Redirect } from "react-router-dom";
import { useCookies } from "react-cookie";
import { post } from "axios";

import { AuthContext } from "../Context";

import Box from "mineral-ui/Box";
import Button from "mineral-ui/Button";
import TextInput from "mineral-ui/TextInput";
import { ThemeProvider } from "mineral-ui/themes";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  flex-shrink: 0;
  margin: 40px auto;
`;

const Login = ({ location }) => {
  const [redirectToReferrer, setRedirectToReferrer] = useState(false);
  const [password, setPassword] = useState("");
  const [authContext, setAuthContext] = useContext(AuthContext);
  const [cookies, setCookie, removeCookie] = useCookies(["annelaureen-token"]);

  const { from } = location.state || { from: { pathname: "/" } };

  if (redirectToReferrer) {
    return <Redirect to={from} />;
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const formData = new FormData();

    formData.append("password", password);

    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    async function postData() {
      try {
        let response = await post("/api/login", formData, config);
        return response;
      } catch (err) {
        console.error(err);
      }
    }

    postData().then((res) => {
      if (res.status === 200) {
        setCookie("annelaureen-token", res.data.token, {
          secure: true,
          sameSite: "strict",
          domain: "annelaureen.nl",
        });
        setAuthContext({
          authenticated: true,
          token: res.data.token,
        });
        setRedirectToReferrer(true);
      } else {
        setPassword("");
      }
    });
  };

  return (
    <ThemeProvider>
      <form onSubmit={handleSubmit}>
        <Container>
          <Box width="300px" marginVertical="0.5em">
            <TextInput
              type="password"
              name="password"
              onChange={(event) => setPassword(event.target.value)}
              value={password}
            />
          </Box>
          <Button type="submit">Inloggen</Button>
        </Container>
      </form>
    </ThemeProvider>
  );
};

export default Login;
