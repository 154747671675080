import styled from "@emotion/styled/macro";
import React from "react";
import axios from "axios";

import Table from "mineral-ui/Table";

import Button from "mineral-ui/Button";
import Box from "mineral-ui/Box";
import Flex from "mineral-ui/Flex";

import Checkbox from "mineral-ui/Checkbox";
import Select from "mineral-ui/Select";

import IconDelete from "mineral-ui-icons/IconDelete";
import IconSave from "mineral-ui-icons/IconSave";

import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import nl from "date-fns/locale/nl";

import { CATEGORIES, CATEGORY_LABELS } from "../../constants";

registerLocale("nl", nl);

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  margin: 40px auto;
`;

const Root = styled.td`
  vertical-align: middle;
  font-weight: inherit;
  text-align: left;
  padding: 2px;
`;

const Img = styled.img`
  object-fit: scale-down;
  width: 120px;
  height: 80px;
`;

function DeleteCell({
  props: {
    children: { id, refetch },
  },
}) {
  const onClick = () =>
    axios
      .delete("/api/media", {
        data: {
          id,
        },
      })
      .then((result) => refetch());

  return (
    <Root>
      <Flex as="span" justifyContent="center" alignItems="center">
        <Button
          onClick={onClick}
          variant="danger"
          iconEnd={<IconDelete />}
          size="small"
        />
      </Flex>
    </Root>
  );
}

function PreviewCell({ props: children }) {
  return (
    <Root>
      <Flex as="span" justifyContent="center" alignItems="center">
        <Img
          src={`https://storage.googleapis.com/ys-node-01/annelaureen.nl/${children.children}`}
        />
      </Flex>
    </Root>
  );
}

function FrontpageCell({
  props: {
    children: { id, refetch, value },
  },
}) {
  const onClick = (checked) =>
    axios
      .put("/api/media", {
        data: {
          id,
          frontpage: checked,
        },
      })
      .then((result) => refetch());

  return (
    <Root>
      <Flex as="span" justifyContent="center" alignItems="center">
        <Checkbox
          onChange={(event) => onClick(event.target.checked)}
          checked={value}
        />
      </Flex>
    </Root>
  );
}

function CategoryCell({
  props: {
    children: { id, refetch, value },
  },
}) {
  const onChange = (value) =>
    axios
      .put("/api/media", {
        data: {
          id,
          category: value,
        },
      })
      .then((result) => refetch());

  return (
    <Root>
      <Flex as="span" justifyContent="center" alignItems="center">
        <Box marginHorizontal="1em" width="100%">
          <Select
            data={CATEGORIES.map(({ label, key }) => ({
              text: label,
              value: key,
            }))}
            onChange={(value) => onChange(value.value)}
            defaultSelectedItem={{
              text: CATEGORY_LABELS[value],
              value,
            }}
          />
        </Box>
      </Flex>
    </Root>
  );
}

function PublishedCell({
  props: {
    children: { id, refetch, value },
  },
}) {
  const onClick = (checked) =>
    axios
      .put("/api/media", {
        data: {
          id,
          published: checked,
        },
      })
      .then((result) => refetch());

  return (
    <Root>
      <Flex as="span" justifyContent="center" alignItems="center">
        <Checkbox
          onChange={(event) => onClick(event.target.checked)}
          checked={value}
        />
      </Flex>
    </Root>
  );
}

class DateComponent extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      date: props.children.value,
      edited: false,
    };

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    this.setState({
      edited: false,
    });
    axios
      .put("/api/media", {
        data: {
          id: this.props.children.id,
          date: this.state.date,
        },
      })
      .then((result) => this.props.children.refetch());
  }

  render() {
    const Input = ({ value, onClick }) => (
      <Button onClick={onClick}>{value}</Button>
    );

    return (
      <Root>
        <Flex
          as="span"
          direction="row"
          justifyContent="between"
          alignItems="center"
        >
          <DatePicker
            preventOpenOnFocus
            shouldCloseOnSelect
            showTimeInput
            timeInputLabel="Tijd:"
            dateFormat="yyyy/MM/dd HH:mm"
            locale="nl"
            selected={new Date(this.state.date)}
            onChange={(date) => this.setState({ date, edited: true })}
            customInput={<Input />}
          />
          <Box marginHorizontal="1em">
            <Button
              size="medium"
              onClick={this.onClick}
              iconStart={<IconSave />}
              aria-label="save"
              disabled={!this.state.edited}
            />
          </Box>
        </Flex>
      </Root>
    );
  }
}

const DateCell = ({ props }) => <DateComponent {...props} />;

function List({ data }) {
  const columns = [
    { content: "Preview", key: "thumbnail", cell: PreviewCell },
    {
      content: "Categorie",
      key: "category",
      cell: CategoryCell,
    },
    {
      content: "Datum",
      key: "date",
      cell: DateCell,
    },
    { content: "Voorpagina", key: "frontpage", cell: FrontpageCell },
    { content: "Gepubliceerd", key: "published", cell: PublishedCell },
    { content: "Verwijderen", key: "delete", cell: DeleteCell },
  ];

  return (
    <Container>
      <Box marginVertical="1em">
        <Table columns={columns} data={data} rowKey="id" title="Media" />
      </Box>
    </Container>
  );
}

export default List;
