import React, { useContext } from "react";
import { Route, Redirect } from "react-router-dom";

import { AuthContext } from "../Context";

const PrivateRoute = ({ component: Component, authentication, ...rest }) => {
  const [authContext, setAuthContext] = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) =>
        authContext.authenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRoute;
