import React, { useState, createContext } from "react";
import { useCookies } from "react-cookie";
import jwtDecode from "jwt-decode";

export const AuthContext = createContext();

const initialAuthState = (cookies) => {
  let result = {
    authenticated: false,
    token: null,
  };
  try {
    const jwt = jwtDecode(cookies["annelaureen-token"]);
    const now = Date.now() / 1000;

    if (jwt.exp > now) {
      result = {
        authenticated: true,
        token: cookies["annelaureen-token"],
      };
    }
  } catch (error) {}

  return result;
};

export const AuthProvider = (props) => {
  const [cookies, setCookie, removeCookie] = useCookies(["annelaureen-token"]);
  const [authContext, setAuthContext] = useState(initialAuthState(cookies));
  return (
    <AuthContext.Provider value={[authContext, setAuthContext]}>
      {props.children}
    </AuthContext.Provider>
  );
};
