import React, { useState } from "react";
import styled from "@emotion/styled/macro";
import { Formik } from "formik";
import { post } from "axios";
import DotLoader from "react-spinners/DotLoader";

import Button from "mineral-ui/Button";
import { FormField } from "mineral-ui/Form";
import Flex, { FlexItem } from "mineral-ui/Flex";
import Box from "mineral-ui/Box";

import Select from "mineral-ui/Select";
import Checkbox from "mineral-ui/Checkbox";

import { CATEGORIES } from "../../constants";

const Img = styled.img`
  object-fit: scale-down;
  width: 196px;
  height: 150px;
`;

function Thumbnail(file) {
  const [image, setImage] = useState(null);
  const reader = new FileReader();
  reader.readAsDataURL(file.file);

  reader.onloadend = function (e) {
    setImage(reader.result);
  };

  return (
    <Box marginRight="1em">
      <Img src={image} />
    </Box>
  );
}

function ImageForm({ file, handleChange, setFieldValue, values }) {
  const name = file.name.split(".").join("");
  return (
    <Flex direction="column" width="100%" justifyContent="between">
      <Box marginVertical="0.5em">
        <FlexItem>
          <Thumbnail file={file} />
        </FlexItem>
        <FlexItem>
          <Box marginVertical="0.5em">
            <Checkbox
              onChange={handleChange}
              name={`${name}.frontpage`}
              value={values[`${name}.frontpage`]}
              label="Voorpagina"
            />
          </Box>
        </FlexItem>
        <FlexItem>
          <Box marginVertical="0.5em">
            <Checkbox
              onChange={handleChange}
              name={`${name}.watermark`}
              value={values[`${name}.watermark`]}
              label="Watermerk"
              defaultChecked
            />
          </Box>
        </FlexItem>
        <FlexItem>
          <Box marginVertical="0.5em">
            <FormField label="Categorie">
              <Select
                data={CATEGORIES.map(({ label, key }) => ({
                  text: label,
                  value: key,
                }))}
                onChange={(value) =>
                  setFieldValue(`${name}.category`, value.value)
                }
                name={`${name}.category`}
                defaultSelectedItem={{
                  text: CATEGORIES[0].label,
                  value: CATEGORIES[0].key,
                }}
                label="Categorie"
              />
            </FormField>
          </Box>
        </FlexItem>
      </Box>
    </Flex>
  );
}

const Create = ({ refetch }) => {
  return (
    <Formik
      initialValues={{ gallery: [] }}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const formData = new FormData();
        const { gallery, ...fields } = values;

        Object.keys(fields).forEach((key) =>
          formData.append(key, JSON.stringify(fields[key]))
        );

        for (let i = 0; i < gallery.length; i++) {
          formData.append("gallery", gallery[i]);
        }

        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };

        async function postData() {
          try {
            let response = await post("/api/media", formData, config);
            return response;
          } catch (err) {
            console.error(err);
          }
        }

        postData().then((res) => {
          refetch();
          setSubmitting(false);
          resetForm();
        });
      }}
      render={({
        values,
        handleChange,
        handleSubmit,
        isSubmitting,
        setFieldValue,
      }) => (
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <Flex direction="column" width="100%" justifyContent="between">
            {isSubmitting ? (
              <FlexItem alignSelf="center">
                <Box marginVertical="4em">
                  <DotLoader size={80} color={"#19b090"} loading={true} />
                </Box>
              </FlexItem>
            ) : (
              <>
                <FlexItem alignSelf="center">
                  <Box marginVertical="2em">
                    <FormField label="Toevoegen">
                      <input
                        id="gallery"
                        name="gallery"
                        type="file"
                        multiple
                        onChange={(event) => {
                          setFieldValue("gallery", event.currentTarget.files);
                        }}
                      />
                    </FormField>
                  </Box>
                </FlexItem>

                <Flex direction="row" wrap width="100%" justifyContent="center">
                  {Array.from(values.gallery).map((file, i) => (
                    <FlexItem>
                      <ImageForm
                        handleChange={handleChange}
                        setFieldValue={setFieldValue}
                        values={values}
                        key={i}
                        file={file}
                      />
                    </FlexItem>
                  ))}
                </Flex>
              </>
            )}

            {values.gallery.length > 0 && (
              <FlexItem alignSelf="center">
                <Button primary type="submit" disabled={isSubmitting}>
                  {isSubmitting ? "Laden..." : "Toevoegen"}
                </Button>
              </FlexItem>
            )}
          </Flex>
        </form>
      )}
    />
  );
};

export default Create;
