import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import styled from "@emotion/styled/macro";

import { Over, Login, Admin } from "./Pages";
import { Header, Footer } from "./Sections";

import { Gallery, PrivateRoute } from "./Components";

import { CATEGORIES } from "./constants";

const Container = styled.div`
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 20px;
`;

const Content = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  background: white;
`;

const App = () => (
  <BrowserRouter>
    <Container>
      <Header />
      <Content>
        <Switch>
          <Route exact path="/" render={(props) => <Gallery {...props} />} />
          {CATEGORIES.map((category, i) => (
            <Route
              key={i}
              exact
              path={`/${category.key}`}
              render={(props) => <Gallery {...props} category={category.key} />}
            />
          ))}
          <Route path="/over" component={Over} />
          <Route path="/login" component={Login} />
          <PrivateRoute path="/admin" component={Admin} />
        </Switch>
      </Content>
      <Footer />
    </Container>
  </BrowserRouter>
);

export default App;
