import styled from "@emotion/styled/macro";
import React from "react";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  flex-shrink: 0;
  margin: 40px auto;
`;

const Img = styled.img`
  width: 70%;
  height: 70%;
  @media screen and (min-width: 768px) {
    width: 50%;
    height: 50%;
  }
`;

const Text = styled.p`
  margin-top: 1em;
  margin-bottom: 1em;
  line-height: 1.7em;
`;

const Title = styled.p`
  font-size: 1.2em;
  font-weight: bold;
  margin-top: 2em;
  margin-bottom: 1em;
  line-height: 1.7em;
`;

const Over = () => (
  <Container>
    <Img src="/profile_new.jpg"></Img>
    <Title>
      Fotograaf van mensen. Van de chaos, de emoties. Het gewone en het
      bijzondere. Jouw verhaal op beeld.
    </Title>
    <Text>
      Ik ben Laureen (voluit Anne Laureen van Rijckevorsel-Klok), geboren in
      Australië in 1991 en overal en nergens opgegroeid. Ik ben ondertussen
      gesetteld in het prachtige Alkmaar met mijn man en twee kinderen. Al sinds
      mijn tienerjaren ben ik gefascineerd door fotografie en allerlei vormen
      van kunst. Tijdens mijn studie Theaterwetenschap kwam ik in aanraking met
      theaterfotografie en volgde ik allerlei cursussen en -workshops om meer te
      leren over fotografie. Ik kreeg steeds meer opdrachten binnen; van
      theater- en dansfotografie, tot concerten, bruiloften, evenementen en
      allerlei mooie gezinnen.
    </Text>
    <Text>
      Als fotograaf leg ik jullie graag vast zoals jullie zijn. Een kijkje in
      jouw of jullie leven. Op avontuur in het bos, banjerend over het strand,
      knuffelend op het grote bed of een koffie ochtend thuis op de bank. Juist
      de gewone momenten die je later zal missen. Want de tijd gaat zo snel en
      ik bevries deze kostbare tijd door middel van foto's. Ik zoek naar
      schoonheid in het alledaagse. En schoonheid hoeft niet perfect te zijn of
      gestyled, want zo is het leven niet. Ik hou van echt, van gewoon zoals het
      is. Want dat mag er zijn. Bij mij zal een fotosessie gezellig en
      ontspannen zijn. Spelenderwijs fotografeer ik. Wees jezelf en ik vind
      prachtige momenten om vast te leggen. Onbetaalbare herinneringen om voor
      altijd te koesteren.
    </Text>
  </Container>
);

export default Over;
